import React from "react"
import PropTypes from "prop-types"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error boundary catched error:", error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Failed to load {this.props.name || `a component`}.</h1>
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
