import React, { Suspense } from "react"
import PropTypes from "prop-types"
import ErrorBoundary from "utils/error-boundary"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout from "components/layout"
import SEO from "components/seo"
import Toc from "components/toc"
import { rhythm, scale } from "utils/typography"
import mediaQuery from "utils/mediaQuery"

import slugs from "github-slugger"

const PostFooter = React.lazy(() => import(/* webpackPrefetch: true */ "components/post-footer"))

const TocForSmallScreensContainer = styled.div`
  ${mediaQuery.minMedium} {
    display: none;
  }
`

const preprocessHeading = (h) => {
  const cleanValue = h.value.replace(/<(\/)?[^>]+>/g, "").replace(/\s{2,}/g, " ")
  return {
    depth: h.depth,
    value: cleanValue,
    id: slugs.slug(cleanValue),
  }
}

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  const disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl + location.pathname}`,
    identifier: location.pathname,
    title: post.frontmatter.title,
  }

  const image = post.frontmatter.image ? post.frontmatter.image.childImageSharp.resize : null

  const isPost = post.frontmatter.isPost !== false
  const isSSR = typeof window === "undefined"
  if (!post.frontmatter.description) {
    throw `no post ${post.frontmatter.title} description`
  }

  const headings = isPost && post.headings.filter((h) => h.depth >= 2 && h.depth <= 4).map(preprocessHeading)
  const toc = (isPost || post.frontmatter.needToC) && (
    <Toc prebuiltHeadings={headings} containerSelector="#content section" />
  )

  return (
    <Layout sidebar={toc || null}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={image}
        pathname={location.pathname}
      />
      {toc && <TocForSmallScreensContainer>{toc}</TocForSmallScreensContainer>}
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date} {isPost && `| ${post.fields.readingTime.text}`}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
            marginTop: rhythm(1),
          }}
        />
        {isPost && !isSSR && (
          <ErrorBoundary name="post footer">
            <Suspense fallback={<div>The post footer is loading...</div>}>
              <PostFooter post={post} disqusConfig={disqusConfig} />
            </Suspense>
          </ErrorBoundary>
        )}
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

const nodePropTypes = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
})

BlogPostTemplate.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    previous: nodePropTypes,
    next: nodePropTypes,
  }).isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteUrl: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        isPost: PropTypes.bool,
        needToC: PropTypes.bool,
        tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        image: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            resize: PropTypes.shape({
              src: PropTypes.string.isRequired,
              height: PropTypes.number.isRequired,
              width: PropTypes.number.isRequired,
            }).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
      headings: PropTypes.arrayOf(
        PropTypes.shape({
          depth: PropTypes.number.isRequired,
          value: PropTypes.string.isRequired,
        })
      ),
      fields: PropTypes.shape({
        readingTime: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        isPost
        needToC
        tags
        image: featured {
          childImageSharp {
            resize(width: 1200, fit: INSIDE) {
              src
              height
              width
            }
          }
        }
      }
      headings {
        depth
        value
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
